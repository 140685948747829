<template>
  <v-dialog v-model="show" persistent width="460px">
    <!-- Wrapper -->
    <v-card class="wrapper pa-3" :style="[{ borderTop: `4px solid ${mainColor}` }]">
      <div>
        <div class="d-flex justify-space-between align-center">
          <h3>{{ translations.changeProjectOrder }}</h3>

          <v-btn @click="$emit('close')" icon small fab color="main">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </div>

        <div class="my-2">
          <v-text-field
            v-model="projectSearch"
            @input="
              value => {
                $emit('search-for-projects', value);
              }
            "
            hide-details
            dense
            outlined
            multiple
            :label="translations.findProject"
            background-color="#fff"
            class="mr-2 project-search"
          >
            <template v-slot:append>
              <div v-if="projectSearch !== ''" class="d-flex align-center project-count">
                <span>{{ filteredProjectsActive }} / {{ filteredProjectsLength }}</span>
              </div>
            </template>

            <template v-slot:append-outer>
              <div v-if="projectSearch !== ''" class="d-flex align-center">
                <div>
                  <div>
                    <v-btn @click="$emit('scroll-to-next-project', { value: false, text: projectSearch })" icon x-small
                      ><v-icon color="main">{{ icons.mdiArrowUpDropCircle }}</v-icon></v-btn
                    >
                  </div>
                  <div>
                    <v-btn @click="$emit('scroll-to-next-project', { value: true, text: projectSearch })" icon x-small
                      ><v-icon color="main">{{ icons.mdiArrowDownDropCircle }}</v-icon></v-btn
                    >
                  </div>
                </div>
              </div>
            </template>
          </v-text-field>
        </div>
      </div>
      <!-- Projects Container   -->
      <v-card-text class="scroll-bar pa-0" style="height: 700px; max-height: 700px;">
        <div class="pr-2 p-b2">
          <draggable v-model="draggableProjects" @change="changeRowOrder" :options="{ handle: '.handle' }">
            <div v-for="project in draggableProjects" :key="`project-item-${project.id}`" class="d-flex align-center" style="width: 100%;" :draggable="true">
              <v-icon class="mr-2 handle" style="cursor: move">{{ icons.mdiDragVertical }}</v-icon>
              <div class=" px-3 py-3 my-1" :class="`project-list-item-${project.projectStatus.id} ${project.keyCode}`" style="border-radius: 3px; width: 100%">
                {{ `${project.keyCode}-${project.label}` }}
              </div>
            </div>
          </draggable>
        </div>
      </v-card-text>
    </v-card>

    <v-overlay :value="loading"></v-overlay>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiDragVertical, mdiArrowDownDropCircle, mdiArrowUpDropCircle } from "@mdi/js";
import draggable from "vuedraggable";

import { translations } from "@/utils/common";
import updatePositionMutation from "@/graphql/UpdatePosition.gql";

export default {
  name: "ProjectOrderingDialog",

  components: {
    draggable
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },

    projects: {
      type: Array,
      required: true,
      default: () => []
    },

    loading: {
      type: Boolean,
      required: true
    },

    projectSearchProp: {
      type: String,
      default: ""
    },

    filteredProjectsActive: {
      type: Number,
      default: 0
    },

    filteredProjectsLength: {
      type: Number,
      default: 0
    }
  },

  data: () => ({
    draggableProjects: [],
    projectSearch: ""
  }),

  created() {
    this.draggableProjects = this.projects;
    this.projectSearch = this.projectSearchProp;
  },

  methods: {
    changeRowOrder(value) {
      this.$emit("start-order-change");
      const { element, newIndex } = value.moved;

      const positionOfOldIndex = this.projects[newIndex].position;
      const projectId = element.id;

      this.updateProjectPositionHandler(projectId, positionOfOldIndex);
    },

    async updateProjectPositionHandler(projectId, positionToAssign) {
      try {
        await this.$apollo
          .mutate({
            mutation: updatePositionMutation,
            variables: {
              projectId: projectId,
              newPosition: positionToAssign
            }
          })
          .catch(error => {
            console.log(error);
          });

        this.$emit("end-order-change");
      } catch (e) {
        console.log(e);
      }
    }
  },

  computed: {
    icons: () => ({ mdiClose, mdiDragVertical, mdiArrowDownDropCircle, mdiArrowUpDropCircle }),

    mainColor() {
      return this.$vuetify.theme.themes.light.main;
    },

    translations: () => translations
  },

  watch: {
    projects(newVal) {
      this.draggableProjects = newVal;
    }
  }
};
</script>

<style scoped>
.project-list-item-1 {
  /* border-left: 4px solid #104272; */
  background-color: #cbdeff;
  padding: 0px;
  color: #4d4d4d !important;
}

.project-list-item-2 {
  /* border-left: 4px solid #104272; */
  background-color: #7cbdfc;
  padding: 0px;
  color: #4d4d4d !important;
}
.project-list-item-3 {
  /* border-left: 4px solid #104272; */
  background-color: #005ab1;
  padding: 0px;
  color: white !important;
}

.scroll-bar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 5px;
}

/* Track */
.scroll-bar::-webkit-scrollbar-track {
  background: #f6f8fa;
  border-radius: 5px;
}

/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
  background: #d0cfcf;
  border-radius: 5px;
}

/* Handle on hover */
.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
}

.scroll-bar {
  overflow-y: auto;
}
</style>
